




















































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment-timezone';
import { Global, Props, User, Reports } from '@/store';
import { Prop, Watch } from 'vue-property-decorator';
import API from '@/modules/API';
import Utils from '@/modules/Utils';
import DatePicker from '@/pages/reports/components/DatePicker.vue';

@Component({
  components: {
    DatePicker
  }
})
export default class ActivityLogReport extends Vue {
  @Global.State('timezone') projectTimezone;
  @Global.State('lang') lang;
  @Global.Mutation('setPageTitle') setPageTitle;
  @Props.State('list') projectProperties;
  @User.State('project') project;
  @Reports.State('reportsList') devices;
  @Reports.Getter('commissionedFixtures') commissioned_fixtures;
  @Reports.Getter('commissioned_map') commissioned_map;
  @Reports.State('devicesCount') devicesCount;

  loading = true;
  isDatePickerOpen = false;
  start_date = null;
  end_date = null;
  tickets = [];
  group_id = null;
  noData = false;
  search_loading = false;
  search = '';
  filtered_items = [];
  formatted_commissioned = [];
  selected_device = null;
  set_default = true;
  history_results = [];
  tableData = [];
  headers = ['Start', 'End', 'Total [hours]', 'Power Consumption [kWh]'];
  select = null;

  mounted() {
    this.setPageTitle('Activity Log Report');
    this.generatePageData();
  }

  @Watch('devices')
  @Watch('devicesCount')
  generatePageData(){
    if (this.devices.length !== this.devicesCount) return;
    this.loading = true;
    this.noData = false;
    this.setDefaultDates();
    this.setGeneralData();
    this.loading = false;
  }

  setDefaultDates() {
    this.set_default = true;
    this.setStartDate(moment().tz(this.projectTimezone).format('YYYY-MM-DD'));
    this.setEndDate(moment().tz(this.projectTimezone).format('YYYY-MM-DD'));
    this.set_default = false;
  }

  setStartDate(start) {
    this.start_date = start;
  }

  setEndDate(end) {
    this.end_date = end;
  }

  setDeviceClicked(device) {
    this.selected_device = device;
  }

  setGeneralData() {
    this.formatted_commissioned = this.commissioned_fixtures.map((device) => {
      const metaDevice = this.commissioned_map.get(device.id)['meta.device'];
      return {
        id: device.id,
        name: device.name,
        cabinet_id: metaDevice.cabinet_id || '',
        circuit_number: metaDevice.circuit_number || '',
        pole_number: metaDevice.pole_number || ''
      };
    });
    this.filtered_items = this.formatted_commissioned;
  }

  querySelections() {
    this.search_loading = true;
    this.filtered_items = this.formatted_commissioned.filter((device) => {
      return (
        device.name.toLowerCase().includes(this.search.toLowerCase()) ||
        device.pole_number.toLowerCase().includes(this.search.toLowerCase()) ||
        device.cabinet_id.toLowerCase().includes(this.search.toLowerCase()) ||
        device.circuit_number.toLowerCase().includes(this.search.toLowerCase())
      );
    });
    this.search_loading = false;
  }

  @Watch('search')
  filterItems() {
    if (this.search) {
      this.querySelections();
    }
  }

  @Watch('start_date')
  @Watch('end_date')
  @Watch('selected_device')
  clearData(val) {
    this.history_results = [];
    this.tableData = [];
    this.noData = false;
  }

  async updateActivityTable() {
    this.loading = true;
    await this.fetchPowerConsumptionByDate();
    this.loading = false;
  }

  async fetchPowerConsumptionByDate() {
    this.noData = false;
    this.history_results = [];
    this.tableData = [];

    try {
      if (this.start_date > this.end_date) {
        const temp = this.start_date;
        this.start_date = this.end_date;
        this.end_date = temp;
      }

      const start = moment(`${this.start_date} 00:00:00`).valueOf();
      const end = moment(`${this.end_date} 23:59:59`).valueOf();

      const url = `https://yoqci9gzri.execute-api.us-east-1.amazonaws.com/dashboard-api/get_power_consumption_ranges`;
      const body = {
        company_id: this.project.company,
        project_id: this.project.id,
        device_id: this.selected_device.id,
        start_time: start,
        end_time: end
      };

      const response = await API.externalAPI(url, 'POST', {}, body, true);
      if (response.result && !response.result.length) {
        this.noData = true;
      } else {
        this.noData = false;
        this.history_results = response.result;
        this.generatePowerConsumptionTable();
      }
    } catch (e) {
      this.noData = true;
    }
  }

  generatePowerConsumptionTable() {
    this.history_results.forEach((history, index) => {
      if (index < this.history_results.length - 1) {
        const duration = this.calculateDuration(
          history.range.start,
          history.range.end
        );

        this.tableData.push({
          start: moment(history.range.start)
            .tz(this.projectTimezone)
            .format('DD-MM-YYYY HH:mm'),
          end: moment(history.range.end)
            .tz(this.projectTimezone)
            .format('DD-MM-YYYY HH:mm'),
          duration,
          power_consumption: history.value
        });
      }
    });
    this.tableData.reverse();
  }

  calculateDuration(timestamp1, timestamp2) {
    let time = '';
    const duration = Math.abs(
      Math.floor(
        Utils.calculateDateDifference(timestamp1, timestamp2, 'minutes')
      )
    );

    const hours = Math.floor(duration / 60);
    time = hours < 10 ? `0${hours}` : `${hours}`;
    const minutes = duration - hours * 60;
    return minutes < 10 ? `${time}:0${minutes}` : `${time}:${minutes}`;
  }

  removeDuplicates() {
    const without_duplicates = [this.history_results[0]];
    let last_relevant_history = this.history_results[0];
    this.history_results.forEach((current_history) => {
      if (
        Math.abs(
          current_history.value.toFixed(2) -
            last_relevant_history.value.toFixed(2)
        ) >= 1.0
      ) {
        without_duplicates.push(current_history);
        last_relevant_history = current_history;
      }
    });

    without_duplicates.forEach((history) =>
      console.log(
        moment(history.timestamp)
          .tz(this.projectTimezone)
          .format('DD-MM-YYYY HH:mm'),
        history.value
      )
    );

    return without_duplicates;
  }

  getFormattedDate(date) {
    return moment(date, 'YYYY-MM-DD')
      .tz(this.projectTimezone)
      .format('DD-MM-YYYY');
  }

  getItemText(item) {
    return `${item.name} ${item.cabinet_id} ${item.circuit_number} ${item.pole_number}`;
  }
}
